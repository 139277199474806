import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import './assets/style/index.scss' // global css
import i18n from './lang'
import {tips, resetForm, pageLoading} from './utils/commonUtil';
import vueMeta from "vue-meta"
import router from './router'
import store from './store'
import Dict from './utils/dict'
import './permission'

// 全局方法挂载
Vue.prototype.$dict = new Dict();
Vue.prototype.$tips = tips
Vue.prototype.$resetForm = resetForm
Vue.prototype.$pageLoading = pageLoading


Vue.config.productionTip = false
Vue.use(vueMeta)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')