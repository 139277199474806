<!--
 * @Descripttion: 
 * @Author: JoyWang1024@163.com
 * @Date: 2022-03-25 17:23:51
-->
<template>
<div >
    <router-view />
</div>
</template>

<script>

export default {
}
</script>

<style scoped>
</style>