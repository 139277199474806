import { setLangAsync, getLanguage } from "../../lang";

const app = {
  state:{
    language: getLanguage()
  },

  mutations:{
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      localStorage.setItem('language',language)
    }
  },

  actions:{
    setLanguage({commit},lang){
      return new Promise(async (resolve,reject) => {
        const language = await setLangAsync(lang);
        commit('SET_LANGUAGE',language);
        resolve(language)
      })
    }
  }
}

export default{
  namespaced: true,
  ...app
}