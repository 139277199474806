import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Dict from '../utils/dict';

Vue.use(VueI18n)

const ASLangs = require("./langList");
const messages = {
  "en": {},
  "zh-CN": {},
}

// 初始化
for (let i = 0; i < ASLangs.length; i++) {
  const {fileName} = ASLangs[i];
  messages[fileName] = {}
}


export const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages,
  silentTranslationWarn: true

})
export function getLanguage(lang) {
  const locales = Object.keys(messages);
  let language;
  const browserLanguage = filterLangByParmas(navigator.language || navigator.browserLanguage)
  if (locales.indexOf(lang) != -1) {
    // 判断当前设置的语言是否被支持 支持-设置语言 不支持-读取平台设置的语言
    language = lang;
  } else if (locales.indexOf(localStorage.getItem("language")) != -1) {
    // 判断平台设置的语言是否被支持 支持-设置语言 不支持-读取浏览器默认语言
    language = localStorage.getItem("language");
  } else if (locales.indexOf(browserLanguage) != -1) {
    // 判断浏览器设置的语言是否支持 支持-设置语言 不支持-判断是国外版还是国内版
    language = browserLanguage;
  } else {
    // 判断是国外版本 展示英文
    language = "en";
  }
  return language
}
export function loadLangFile(lang) {
  if (!Object.keys(messages[lang]).length) {
    return import(/* webpackChunkName: "[request]" */`./data/${lang}.js`).then(res => {
      messages[lang] = {
        ...res.default
      }
      i18n.setLocaleMessage(lang, Object.assign(messages[lang]));
      return messages
    })
  } else {
    return Promise.resolve(messages)
  }
}
export function setLangAsync(lang) {
  return new Promise(async (res,rej) => {
    const language = getLanguage(lang);
    // 加载语言
    await loadLangFile(language);
    i18n.locale = language;
    Vue.prototype.$dict = new Dict();
    res(language)
  })
}


/* 根据参数 对应指定的lang */
export function filterLangByParmas(lang) {
  for (let i = 0; i < ASLangs.length; i++) {
    const { value, localeLang } = ASLangs[i];
    if (lang == value || lang == localeLang) {
      return value
    }
  }

}



export default i18n
