/*
 * @Descripttion: 按需引入
 * @Author: JoyWang1024@163.com
 * @Date: 2022-03-25 16:40:07
 */
import Vue from 'vue'
import { 
  Menu,
  Submenu,
  MenuItem,
  Loading,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Carousel,
  CarouselItem,
  Skeleton,
  SkeletonItem,

  Image,
  Col,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Button,
  Form,
  FormItem,
  Select,
  Option,
  Dialog,
  Upload,
  Message,
  MessageBox
} from 'element-ui'

Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Loading)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Skeleton)
Vue.use(SkeletonItem)

Vue.use(Image)
Vue.use(Col)
Vue.use(Row)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Upload)

// Vue.prototype.$ELEMENT = {size: "small"}
Vue.prototype.$message = Message
Vue.prototype.$confirm  = MessageBox.confirm
