/*
 * @Descripttion: 
 * @Author: JoyWang1024@163.com
 * @Date: 2022-03-27 16:57:03
 */
import Layout from '../layout'
export default [{
  path: '/',
  component: Layout,
  hidden: true,
  children: [{
    path: '/',
    component: (resolve) => require(['../views/download.vue'], resolve),
    meta: {
      title: 'g.serAndsup'
    }
  }, ]
}]