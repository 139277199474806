/**
 * 通用js方法封装处理
 */
import Vue from "vue"
const baseURL = process.env.VUE_APP_BASE_API
let vm = new Vue();
// 日期格式化
export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

// 表单重置
export function resetForm(refName) {
	if (this.$refs[refName]) {
		try {
			this.$refs[refName].resetFields();
		} catch (error) {
		}
		this.$refs[refName].clearValidate();

	}
}

// 添加日期范围
export function addDateRange(params, dateRange, propName) {
	var search = params;
	search.params = {};
	if (null != dateRange && '' != dateRange) {
		if (typeof (propName) === "undefined") {
			search.params["beginTime"] = dateRange[0];
			search.params["endTime"] = dateRange[1];
		} else {
			search.params["begin" + propName] = dateRange[0];
			search.params["end" + propName] = dateRange[1];
		}
	}
	return search;
}


// 字典数组数据回显
export function selectDictText(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].value == ('' + value)) {
			actions.push(datas[key].text);
			return true;
		}
	})
	return actions.join('');
}
// 回显数据字典（字符串数组）
export function selectDictTexts(datas, value, separator) {
	var actions = [];
	var currentSeparator = undefined === separator ? "," : separator;
	var temp = value.split(currentSeparator);
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].value == ('' + temp[val])) {
				actions.push(datas[key].text + currentSeparator);
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1);
}




// 字典数组数据回显
export function selectDictLabel(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].value == ('' + value)) {
			actions.push(datas[key].text);
			return true;
		}
	})
	return actions.join('');
}
// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
	var actions = [];
	var currentSeparator = undefined === separator ? "," : separator;
	var temp = value.split(currentSeparator);
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].value == ('' + temp[val])) {
				actions.push(datas[key].text + currentSeparator);
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1);
}

// 通用下载方法
export function download(fileName) {
	window.location.href = baseURL + "/common/download?fileName=" + encodeURI(fileName) + "&delete=" + true;
}

// 字符串格式化(%s )
export function sprintf(str) {
	var args = arguments, flag = true, i = 1;
	str = str.replace(/%s/g, function () {
		var arg = args[i++];
		if (typeof arg === 'undefined') {
			flag = false;
			return '';
		}
		return arg;
	});
	return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
	if (!str || str == "undefined" || str == "null") {
		return "";
	}
	return str;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
	let config = {
		id: id || 'id',
		parentId: parentId || 'parentId',
		childrenList: children || 'children'
	};

	var childrenListMap = {};
	var nodeIds = {};
	var tree = [];

	for (let d of data) {
		let parentId = d[config.parentId];
		if (childrenListMap[parentId] == null) {
			childrenListMap[parentId] = [];
		}
		nodeIds[d[config.id]] = d;
		childrenListMap[parentId].push(d);
	}

	for (let d of data) {
		let parentId = d[config.parentId];
		if (nodeIds[parentId] == null) {
			tree.push(d);
		}
	}

	for (let t of tree) {
		adaptToChildrenList(t);
	}

	function adaptToChildrenList(o) {
		if (childrenListMap[o[config.id]] !== null) {
			o[config.childrenList] = childrenListMap[o[config.id]];
		}
		if (o[config.childrenList]) {
			for (let c of o[config.childrenList]) {
				adaptToChildrenList(c);
			}
		}
	}
	return tree;
}
export let tips = {
	loading(text) {
		return vm.$loading({
			lock: true,
			text: text || "加载中",
			background: "rgba(0, 0, 0, 0.7)",
		});
	},

}

// 根据已知对象key 赋值
export function getValByObj(setObj = {}, obj = {}) {
	let tempSetObjKeys = Object.keys(setObj);
	let tempObj = JSON.parse(JSON.stringify(setObj));
	for (let i = 0; i < tempSetObjKeys.length; i++) {
		const k = tempSetObjKeys[i];
		if (obj[k] != undefined) {
			tempObj[k] = obj[k]
		}
	}
	return tempObj
}

/**
 * @Author: JoyWang1024@163.com
 * @Descripttion: 获取字段格式化
 * @Date: 2021-08-26 22:22:28
 * @param {*} str
 * @param {*} v
 * @param {*} l
 */
export function dictFormat(arr, val = "id", text = "name") {
	if (!(arr && arr.length)) {
		return []
	}
	let tempArr = [];
	for (let i = 0; i < arr.length; i++) {
		const item = arr[i];
		tempArr.push({
			ext: item,
			value: item[val],
			text: item[text],
		})
	}
	return tempArr
}

export function guid() {
	return 'xxxx-4xxx-yxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

/**
 * @Author: JoyWang1024@163.com
 * @Descripttion: 根据array key 输出map（map为该item对象）
 * @Date: 2021-09-27 19:08:02
 * @param {*} arr
 * @param {*} key 输出map的key
 */
export function arrayToMapObj(arr, key = "value") {
	let tempMap = {}
	for (let i = 0; i < arr.length; i++) {
		const item = arr[i];
		tempMap[item[key]] = item
	}
	return tempMap
}
/**
 * @Author: JoyWang1024@163.com
 * @Descripttion: 根据array key 输出map（map为该valKey 值）
 * @Date: 2021-09-27 19:08:51
 * @param {*} arr 
 * @param {*} key  输出map的key
 * @param {*} valKey 输出map的value
 */
export function arrayToMapVal(arr, key = "value", valKey = "text") {
	let tempMap = {}
	for (let i = 0; i < arr.length; i++) {
		const item = arr[i];
		tempMap[item[key]] = item[valKey]
	}
	return tempMap
}

/**
 * 清除对象中的值 并移除指定表单校验结果
 * @param {*} obj 对象
 * @param {*} keys 需要清除的key
 * @param {*} elForm 需要重置的form表单
 * @param {*} formItem 需要重置的form表单item
 */
export function clearObjDataAndForm(obj, keys = [], elForm, formItem = []) {
	for (const key in obj) {
		if (Object.hasOwnProperty.call(obj, key)) {
			if (keys.indexOf(key) != -1) {
				obj[key] = undefined;
			}
		}
	}
	if (elForm) {
		this.$refs[elForm].clearValidate([
			...keys,
			...formItem
		]);
	}
}

/**
 * 延迟函数
 * @param {*} ms 延迟时间
 * @param {*} fun 延迟执行的函数
 * @returns Promise
 */
export function delayFun(ms, fun) {
	return new Promise((res, rej) => {
		setTimeout(() => {
			fun && fun();
			res();
		}, ms);
	})
}

export function deepFn(data, parent_code) {
	var result = [], temp;
	for (var i = 0; i < data.length; i++) {
		if (data[i].parent_code == parent_code) {
			var obj = { "label": data[i].name, "value": data[i].code };
			temp = fn(data, data[i].code);
			if (temp.length > 0) {
				obj.children = temp;
			}
			result.push(obj);
		}
	}
	return result;
}


export function pageLoading() {
	return this.$loading({
		lock: true,
		text: 'Loading',
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.7)'
	});
}

