
<template>
  <div id="app" class="lang-zh">
    <router-view id="router_wrap" v-if="isRouterAlive"/>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data(){
    return{
      isRouterAlive: true
    }
  },
  metaInfo() {
    return {
      title: this.$i18n.t('g.downloadTitle'),
      link: [
        {
          // set link
          rel: "icon",
          href: require("./assets/images/favicon.png"),
        },
      ],
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      })
    }
  }
};
</script>

<style>
</style>