/*
 * @Descripttion: 
 * @Author: JoyWang1024@163.com
 * @Date: 2022-06-17 10:14:40
 */
const qs = require("qs")
export const isMobile = () => {
  if (document.body.clientWidth > 768) {
    return false
  } else {
    return true
  }
}

export function getUrlParams4(url){
	let urlStr = url.split('?')[1]
	let result = qs.parse(urlStr)
	// 拼接额外参数
	let otherParams = {
		num:50,
		size:6.1
	}
	let str = qs.stringify(otherParams)
	let newUrl = url + str
	return {result,newUrl}
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
 export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
 export function obj2param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/* 删除url中的指定参数 */
export function removeURLParameter(url, parameter) {
  const obj = param2Obj(url);
  obj[parameter] = undefined;
  const hostPath = url.split('?')[0];
  const param = obj2param(obj);
  if (param) {
    return hostPath + '?' + param
  } else {
    return hostPath;
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
 export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

