/*
 * @Descripttion: 
 * @Author: JoyWang1024@163.com
 * @Date: 
 */
import router from './router'
import store from './store'
import { removeURLParameter, param2Obj } from "./utils"
import { filterLangByParmas } from './lang'

let isInitLang = false;

/* ----------处理语言参数------------------ */
/* 1、获取url中的参数 */
const urlQuery = param2Obj(window.location.href);
const langText = filterLangByParmas(urlQuery.lang) || urlQuery.lang;
/* 2、处理掉url中的语言参数 */
if (langText) {
  const fullPath = removeURLParameter(window.location.href, "lang");
  history.replaceState({},null, fullPath);
}
/* ----------处理语言参数-end------------------ */


/* 设置语言 */
function initLang() {
  return new Promise((resolve, reject) => {
    store.dispatch("app/setLanguage", langText).then(res => {
      isInitLang = true;
      resolve(res);
    }).catch(e => {
      reject(e);
    })
  })
}

router.beforeEach(async (to, from, next) => {
    /* 初始化语言 */
    !isInitLang && await initLang();
    next();
})
